import FacebookIcon from '@eg/elements/Icons/FacebookIcon';
import LinkedinIcon from '@eg/elements/Icons/LinkedinIcon';
import TwitterIcon from '@eg/elements/Icons/TwitterIcon';
import XingIcon from '@eg/elements/Icons/XingIcon';
import YoutubeIcon from '@eg/elements/Icons/YoutubeIcon';
import { withI18n } from 'client/i18n/withI18n';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const FooterComponent = () => (
  <footer className={`esc_container esc_box esc_box--around ${styles.footer}`}>
    <div className={styles.leftContent}>
      &copy;
      <FormattedMessage
        id="footer.left.text"
        values={{
          link: (
            <a href="https://www.ergo.de" className={styles.linkToErgo} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="footer.left.text.link" />
            </a>
          ),
        }}
      />
    </div>
    <div className={styles.socialMedia}>
      <FormattedMessage id="footer.social-media.text" />
      <a href="https://www.facebook.com/ERGO.Deutschland/" target="_blank" rel="noopener noreferrer">
        <FacebookIcon width={30} height={30} />
      </a>
      <a href="https://www.linkedin.com/company/ergo-versicherungsgruppe-ag" target="_blank" rel="noopener noreferrer">
        <LinkedinIcon width={30} height={30} />
      </a>
      <a href="https://twitter.com/ERGOde" target="_blank" rel="noopener noreferrer">
        <TwitterIcon width={30} height={30} />
      </a>
      <a href="https://www.xing.com/company/ergo" target="_blank" rel="noopener noreferrer">
        <XingIcon width={30} height={30} />
      </a>
      <a href="https://www.youtube.com/user/ergo" target="_blank" rel="noopener noreferrer">
        <YoutubeIcon width={30} height={30} />
      </a>
    </div>
  </footer>
);

export const Footer = withI18n(FooterComponent, messages);
